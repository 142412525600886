<template>
  <div class="text-black">
    <div v-if="updated && expire">
      <!-- <p>You can submit feedback only once with this link.</p> -->
      <ErrorPage error="You can submit feedback only once with this link." />
    </div>
    <div v-else-if="expire">
      <p>
        <!-- Your Link for the feedback has been expired. You can't submit feedback
        after 48 hrs with this link. -->
        <ErrorPage
          error="The feedback link has expired. It was only valid for submissions within 48 hours of your Hospital visit."
        />
      </p>
    </div>
    <div v-else-if="updated">
      <!-- <p>You can submit feedback only once with this link.</p> -->
      <ErrorPage
        error="The feedback link has expired. It was only valid for submissions within 48 hours of your Hospital visit."
      />
    </div>
    <div v-else>
      <div class="feedback-container">
        <hr style="border-color: #000; width: 60%" />
        <p class="mt-2"><u>S&amp;VMFC</u></p>
        <p>{{ selectedHospital }}</p>
        <p>FEEDBACK PERFORMA</p>
        <hr style="border-color: #000; width: 60%" />
      </div>
      <!-- Form  -->
      <div class="mt-2 m-1">
        <validation-observer ref="feedbackFormValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="3"></b-col>
              <!-- Name -->
              <b-col md="3">
                <b-form-group>
                  <label for="name" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">Name </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >نام
                      </span>
                    </div>
                  </label>

                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      name="name"
                      disabled
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label for="cnic" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">CNIC </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >شناختی کارڈ
                      </span>
                    </div>
                  </label>

                  <validation-provider
                    #default="{ errors }"
                    name="CNIC"
                    rules="required|integer|length:13"
                  >
                    <b-form-input
                      id="cnic"
                      v-model="cnic"
                      disabled
                      :state="errors.length > 0 ? false : null"
                      name="cnic"
                      placeholder="61101XXXXXXXX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3"></b-col>
              <b-col md="3">
                <!-- Mobile -->
                <b-form-group>
                  <label for="mobile" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">Mobile </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >موبائل</span
                      >
                    </div>
                  </label>

                  <validation-provider
                    #default="{ errors }"
                    name="Mobile"
                    rules="required|integer|length:11"
                  >
                    <b-form-input
                      id="mobile"
                      v-model="mobile"
                      disabled
                      :state="errors.length > 0 ? false : null"
                      name="mobile"
                      placeholder="0310XXXXXXX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <!-- Category -->
                <b-form-group label-for="category">
                  <label for="category" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span style="font-size: 14px">Category </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                          font-size: 14px;
                        "
                        >زمرہ</span
                      >
                    </div>
                  </label>

                  <validation-provider
                    #default="{ errors }"
                    name="Category"
                    rules="required"
                  >
                    <v-select
                      v-model="category"
                      :options="categories"
                      class="vSelectStyle bg-white"
                      placeholder="Select your category"
                      ><small class="text-danger">{{ errors[0] }}</small>
                    </v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <!-- Rating -->
      <div class="mt-2 m-1">
        <!-- Communication -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>1. Communication:</p>
              <span class="urdu">١. &nbsp;&nbsp;&nbsp;تبادلہ خیال</span>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="communicationRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu">١. &nbsp;&nbsp;&nbsp;تبادلہ خیال</span>
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Transportation and Reception Arrangements -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>2. Transportation and reception arrangements:</p>
              <span class="urdu"
                >٢. &nbsp;&nbsp; آمدورفت اور استقبال کے انتظامات
              </span>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="transportRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <span class="urdu"
                  >٢. &nbsp;&nbsp; آمدورفت اور استقبال کے انتظامات
                </span>
              </b-col> -->
          </b-row>
        </div>

        <!-- Wheel Chair / Stretcher Services -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>3. Wheel Chair / stretcher services:</p>
              <span class="urdu">٣. &nbsp;&nbsp;ویل چیئر / سٹریچر سروسز</span>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="stetcherRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu">٣. &nbsp;&nbsp;ویل چیئر / سٹریچر سروسز</span>
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Facilitation at S&VMFC -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>4. Facilitation at S&amp;VMFC:</p>
              <span class="urdu"
                >٤. &nbsp;&nbsp;&nbsp;اس اینڈ وی ایم ایف سی میں سہولت</span
              >
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="facilitationRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu"
                    >٤. &nbsp;&nbsp;&nbsp;اس اینڈ وی ایم ایف سی میں سہولت</span
                  >
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Lab Tests / X Ray / Medicines -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>5. Lab Tests / x-ray / medicines :</p>
              <span class="urdu"
                >٥. &nbsp;&nbsp;&nbsp;لیب ٹیسٹ / ایکس رے / دوائیاں</span
              >
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="labRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu"
                    >٥. &nbsp;&nbsp;&nbsp;لیب ٹیسٹ / ایکس رے / دوائیاں</span
                  >
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Fixing of Appointment with Splt -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>6. Fixing of appointment with splt :</p>
              <span class="urdu"
                >٦. &nbsp;&nbsp;&nbsp;سپیشلسٹ کے ساتھ ملاقات کے وقت کا تعین
                کرنا</span
              >
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="appointmentRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu"
                    >٦. &nbsp;&nbsp;&nbsp;سپیشلسٹ کے ساتھ ملاقات کے وقت کا تعین
                    کرنا</span
                  >
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Nursing Assistant Accompanied -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>7. Nursing assistant accompanied :</p>
              <span class="urdu"
                >٧. &nbsp;&nbsp;&nbsp;نرسنگ اسسٹنٹ کے ہمراہ</span
              >
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="nursingRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu"
                    >٧. &nbsp;&nbsp;&nbsp;نرسنگ اسسٹنٹ کے ہمراہ</span
                  >
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Reports Collection -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>8. Reports collection:</p>
              <span class="urdu">٨. &nbsp;&nbsp;&nbsp;رپورٹس جمع کرنا</span>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="reportRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu">٨. &nbsp;&nbsp;&nbsp;رپورٹس جمع کرنا</span>
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Transportation on Return -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>9. Transportation on return:</p>
              <span class="urdu">٩. &nbsp;&nbsp;&nbsp;واپسی پر ٹرانسپورٹ</span>
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="transportReturnRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu"
                    >٩. &nbsp;&nbsp;&nbsp;واپسی پر ٹرانسپورٹ</span
                  >
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Conduct by Doctor with Self and Family -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>10. Conduct by doctor with self and family:</p>
              <span class="urdu"
                >١٠. &nbsp;&nbsp;&nbsp;ڈاکٹر کا آپ اور آپکے خاندان کے ساتھ
                رویہ</span
              >
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="drConductRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu"
                    >١٠. &nbsp;&nbsp;&nbsp;ڈاکٹر کا آپ اور آپکے خاندان کے ساتھ
                    رویہ</span
                  >
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Conduct / Dress of S&VMFC Staff -->
        <div class="feedback-content mb-50">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p>11. Conduct / dress of S&amp;VMFC staff:</p>
              <span class="urdu"
                >١١. &nbsp;&nbsp;&nbsp;اس اینڈ وی ایم ایف سی کے اسٹاف کا سلوک /
                لباس</span
              >
            </b-col>
            <b-col md="3">
              <div class="radio-buttons">
                <star-rating
                  :star-size="25"
                  v-model="staffConductRating"
                  class="align-stars"
                ></star-rating>
              </div>
            </b-col>
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu"
                    >١١. &nbsp;&nbsp;&nbsp;اس اینڈ وی ایم ایف سی کے اسٹاف کا سلوک
                    / لباس</span
                  >
                </div>
              </b-col> -->
          </b-row>
        </div>

        <!-- Any other Observation / Suggestion -->
        <div class="feedback-content mb-2">
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="4">
              <p class="mb-1">12. Any other observation / suggestion:</p>
              <p class="urdu">١٢. &nbsp;&nbsp;&nbsp;کوئی اور تجاویز / مشاہدے</p>
            </b-col>
            <!-- <b-col md="6">
              <div class="radio-buttons">
                <b-textarea
                  style="height: 50px"
                  type="text"
                  v-model="suggestion"
                ></b-textarea>
              </div>
            </b-col> -->
            <!-- <b-col md="4">
                <div class="text-right">
                  <span class="urdu"
                    >١٢. &nbsp;&nbsp;&nbsp;کوئی اور تجاویز / مشاہدے</span
                  >
                </div>
              </b-col> -->
          </b-row>
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="6">
              <div class="">
                <vue-drawing-canvas
                  ref="VueCanvasDrawing"
                  :image.sync="suggestion"
                  :width="850"
                  :height="500"
                  saveAs="png"
                />
                <b-button
                  type="button"
                  variant="warning"
                  class="ml-auto"
                  @click.prevent="$refs.VueCanvasDrawing.reset()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-file-earmark"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
                    />
                  </svg>
                  Reset
                </b-button>
              </div>
            </b-col>
            <b-col md="3"></b-col>
          </b-row>
        </div>

        <!-- Submit Button -->
        <b-form-group class="text-center">
          <b-button
            class="mb-5"
            type="submit"
            variant="primary"
            pill
            @click="validationForm"
          >
            Submit Feedback
          </b-button>
        </b-form-group>
      </div>
      <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ErrorPage from "@/components/ErrorPage.vue";
import VueDrawingCanvas from "vue-drawing-canvas";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import StarRating from "vue-star-rating";
import { heightTransition } from "@core/mixins/ui/transition";
export default {
  components: {
    StarRating,
    VueDrawingCanvas,
    ValidationProvider,
    ValidationObserver,
    ErrorPage,
  },
  mixins: [util, heightTransition],
  data() {
    return {
      show: false,
      updated: false,
      expire: false,
      communicationRating: 1,
      transportRating: 1,
      suggestion: "",
      staffConductRating: 1,
      drConductRating: 1,
      transportReturnRating: 1,
      reportRating: 1,
      nursingRating: 1,
      appointmentRating: 1,
      labRating: 1,
      facilitationRating: 1,
      stetcherRating: 1,
      mobile: null,
      cnic: null,
      name: null,
      feedbackId: "",
      category: null,
      selectedHospital: null,
      hospital: null,
      categories: [
        "Shuhada Family",
        "Retired Officer",
        "Retired Officer Family",
      ],
    };
  },
  async created() {
    await this.getData(this.$route.params.id);
    this.feedbackId = this.$route.params.id;
  },
  methods: {
    ...mapActions({
      submitFeedback: "appData/submitFeedback",
      getFeedbackData: "appData/getFeedbackData",
    }),
    async validationForm() {
      try {
        const success = await this.$refs.feedbackFormValidation.validate();
        if (success) {
          await this.submit();
        }
      } catch (error) {
        error;
        this.displayError(error);
      }
    },
    onCanvasUpdate(data) {
      this.suggestion = data;
    },

    async getData(feedbackId) {
      try {
        const res = await this.getFeedbackData(feedbackId);

        this.cnic = res.data.cnic;
        this.mobile = res.data.mobile;
        this.name = res.data.name;
        this.selectedHospital = res.data.hospital_name;
        this.updated = res.data.updated;
        this.expire = res.data.expire;
        this.hospital = res.data.hospital;
      } catch (error) {
        this.displayError(error);
      }
    },
    async submit() {
      this.show = true;
      try {
        const res = await this.submitFeedback({
          communication: this.communicationRating,
          transportation_reception: this.transportRating,
          other_observation_suggestion: this.suggestion,
          conduct_dress_SVMFC_staff: this.staffConductRating,
          conduct_doc_self_family: this.drConductRating,
          transportation_on_return: this.transportReturnRating,
          reports_collection: this.reportRating,
          nursing_accompanied: this.nursingRating,
          fixing_appointment: this.appointmentRating,
          lab_xray_medicine: this.labRating,
          facilitation_SVMFC: this.facilitationRating,
          wheel_chair_stretcher: this.stetcherRating,
          hospital: this.hospital,
          cnic: this.cnic,
          mobile: this.mobile,
          name: this.name,
          category: this.category,
          feedbackid: this.feedbackId,
        });

        if (res.status === 200) {
          await this.displaySuccess(
            "Feedback Submitted! You will be contacted on resolution of issue."
          );

          this.$router.push("/");

          this.show = false;
          this.reset();
          this.$refs.feedbackFormValidation.reset();
          this.$refs.VueCanvasDrawing.reset();
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    reset() {
      this.communicationRating = 1;
      this.transportRating = 1;
      this.suggestion = "";
      this.staffConductRating = 1;
      this.drConductRating = 1;
      this.transportReturnRating = 1;
      this.reportRating = 1;
      this.nursingRating = 1;
      this.appointmentRating = 1;
      this.labRating = 1;
      this.facilitationRating = 1;
      this.stetcherRating = 1;
      this.cnic = null;
      this.mobile = null;
      this.name = null;
      this.category = null;
    },
  },
};
</script>


<style scoped>
.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.radio-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.align-stars {
  justify-content: between;
}

p {
  font-weight: 700;
}
.urdu {
  font-family: "Noto Nastaliq Urdu", serif !important;
  font-size: 14px !important;
  font-weight: 600;
  display: flex;
  justify-content: end;
  margin-top: -8px;
}

.feedback-content {
  margin-top: 20px;
  padding-bottom: 25px;
}

.text-black {
  color: black !important;
}
::placeholder {
  color: black;
}
label {
  font-size: 110%;
  font-weight: 400;
  color: #000;
}
</style>
